import MaintenancePage from './components/MaintenancePage';

function App() {
  return (
    <div className="App">
      <MaintenancePage />
    </div>
  );
}

export default App;